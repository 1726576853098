<template lang="pug">
.inline-block
  button.btn.btn-default.btn-dark-new-layout.btn-sm.btn-icon.report-action-button(v-uiv-tooltip.hover="duplicateTooltip" :disabled="isLimited" @click="duplicateReport()")
    i.fa.fa-files-o.fa-lg
</template>

<script lang="ts">
import i18n from "../i18n";
import Vue from "vue";
import Component from "vue-class-component";
import EventBus from "../events/event-bus";
import Events from "../events/events";

@Component({})
export default class DuplicateReportButton extends Vue {
  readonly isLimited = window.zoinedContext.limited;

  get duplicateTooltip() {
    return this.isLimited
      ? `${i18n.t("actions.duplicate")} (${i18n.t("limited_version.available_with_pro")})`
      : i18n.t("actions.duplicate");
  }

  duplicateReport() {
    EventBus.emit(Events.duplicateReport);
  }
}
</script>
