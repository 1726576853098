<!-- wraps bootstrap dropdown -->
<template lang="pug">
.dropdown(ref="dropdown", :class="{ open }")
  slot(name="toggle" v-bind:toggleDropdown="toggle" v-bind:menuOpen="open")
  .dropdown-menu(v-if="open" :class="{ 'dropdown-menu-right': right }")
    slot(name="menu")
</template>

<script>
export default {
  props: {
    right: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  inject: {
    parentDropdown: {
      from: "dropdown",
      default: null,
    },
  },
  provide() {
    return {
      dropdown: this,
    };
  },
  methods: {
    close() {
      this.open = false;

      if (this.parentDropdown) {
        this.parentDropdown.close();
      }
    },
    toggle() {
      this.open = !this.open;
    },
    onDocumentClick(evt) {
      if (this.open && !this.$refs.dropdown.contains(evt.target)) {
        this.open = false;
      }
    },
  },
  created() {
    document.addEventListener("click", this.onDocumentClick);
  },
  destroyed() {
    document.removeEventListener("click", this.onDocumentClick);
  },
  watch: {
    open(value) {
      if (value) {
        this.$emit("open");
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped>
.dropdown {
  display: inline-block;
}
</style>
